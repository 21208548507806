import React, { createContext, useState, useCallback } from 'react';
import { fetchGuest, fetchAuth } from '@utils/fetch';

export const AuthContext = createContext();

const initialState = {
	checking: true,
	logged: false,
	user: null
};

export const AuthProvider = ({ children }) => {

	const [auth, setAuth] = useState(initialState);

	const login = async(data) => {

		const res = await fetchGuest('auth/login', data, 'POST');

		if (res.ok) {
			localStorage.setItem('token', res.access_token);
			const { user } = res;

			setAuth({
				checking: false,
				logged: true,
				user: user
			});

		}

		return res.ok;

	}

	const verifyToken = useCallback( async() => {
		
		const token = localStorage.getItem('token') || '';

		if(!token) {
			setAuth({
				checking: false,
				logged: false,
				user: null
			});

			return false;
		}

		const res = await fetchAuth('auth/show');

		if (res.ok) {
			const { user } = res;

			setAuth({
				checking: false,
				logged:true,
				user: user
			});

			return true;
		} else {
			setAuth({
				checking: false,
				logged: false,
				user: null
			});

			return false;
		}

	}, [])

	const logout = async () => {

		const res = await fetchAuth('auth/logout');

		if (res.ok) {
			localStorage.removeItem('token');

			setAuth({
				checking: false,
				logged: false,
				user: null
			});
		}
	}

	return (
		<AuthContext.Provider value={{
			auth,
			login,
			verifyToken,
			logout,
		}}>
			{ children }
		</AuthContext.Provider>
	)
}