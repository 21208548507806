// utils
import {lazy, Suspense, useContext} from 'react';

// contexts
import {AuthContext} from '@contexts/AuthContext';

// router
import { PublicRoute } from './router/PublicRoute';
import { PrivateRoute } from './router/PrivateRoute';

// components
import ScrollProgress from '@ui/ScrollProgress';
import Panel from '@layout/Panel';
import Sidebar from '@layout/Sidebar';
import {Navigate, Route, Routes} from 'react-router-dom';
import WidgetsLoader from '@components/WidgetsLoader';

// hooks
import usePageIsOverflow from '@hooks/usePageIsOverflow';
import {useRef, useEffect} from 'react';

// pages
const Login = lazy(() => import('@pages/Login'));
const Calendar = lazy(() => import('@pages/Calendar'));
const Patients = lazy(() => import('@pages/Patients'));
const NewPatient = lazy(() => import('@pages/NewPatient'));
const PatientHistory = lazy(() => import('@pages/PatientHistory'));
const FormConsultation = lazy(() => import('@pages/FormConsultation'));
const Services = lazy(() => import('@pages/Services'));
const FormServices = lazy(() => import('@pages/FormServices'));
const Testimonies = lazy(() => import('@pages/Testimonies'));
const FormTestimonies = lazy(() => import('@pages/FormTestimonies'));
const Questions = lazy(() => import('@pages/Questions'));
const FormQuestions = lazy(() => import('@pages/FormQuestions'));
const Posts = lazy(() => import('@pages/Posts'));
const FormPosts = lazy(() => import('@pages/FormPosts'));
const Authors = lazy(() => import('@pages/Authors'));
const FormAuthors = lazy(() => import('@pages/FormAuthors'));
const PageNotFound = lazy(() => import('@pages/PageNotFound'));

const AppLayout = () => {

    const {auth, verifyToken} = useContext(AuthContext);
    const appRef = useRef(null);
    const isOverflow = usePageIsOverflow();

    useEffect(() => {
        verifyToken()
        if (appRef.current) {
            appRef.current.scrollTo(0, 0);
        }
    }, [verifyToken]);

    return (
        <div className="app" ref={appRef}>
            {isOverflow ? <ScrollProgress/> : null}
            {auth.logged ? <Sidebar/> : null}
            <div className={auth.logged ? 'app_content_auth' : 'app_content_guest'}>
                {auth.logged ? <Panel/> : null}
                <Suspense fallback={<WidgetsLoader />}>
                    <Routes>
                        <Route path="/" element={<Navigate to="/calendar"/>}/>
                        <Route
                            path="/login" 
                            element={<PublicRoute Component={Login} />}
                        />
                        <Route 
                            path="/calendar"
                            element={<PrivateRoute Component={Calendar} />}
                        />
                        <Route 
                            path="/patients"
                            element={<PrivateRoute Component={Patients} />}
                        />
                        <Route 
                            path="/patients/new"
                            element={<PrivateRoute Component={NewPatient} />}
                        />
                        <Route 
                            path="/patients/history"
                            element={<PrivateRoute Component={PatientHistory} />}
                        />
                        <Route 
                            path="/consultations/form"
                            element={<PrivateRoute Component={FormConsultation} />}
                        />
                        <Route 
                            path="/services"
                            element={<PrivateRoute Component={Services} />}
                        />
                        <Route 
                            path="/services/form"
                            element={<PrivateRoute Component={FormServices} />}
                        />
                        <Route 
                            path="/testimonies"
                            element={<PrivateRoute Component={Testimonies} />}
                        />
                        <Route 
                            path="/testimonies/form"
                            element={<PrivateRoute Component={FormTestimonies} />}
                        />
                        <Route 
                            path="/questions"
                            element={<PrivateRoute Component={Questions} />}
                        />
                        <Route 
                            path="/questions/form"
                            element={<PrivateRoute Component={FormQuestions} />}
                        />
                        <Route 
                            path="/posts"
                            element={<PrivateRoute Component={Posts} />}
                        />
                        <Route 
                            path="/posts/form"
                            element={<PrivateRoute Component={FormPosts} />}
                        />
                        <Route 
                            path="/authors"
                            element={<PrivateRoute Component={Authors} />}
                        />
                        <Route 
                            path="/authors/form"
                            element={<PrivateRoute Component={FormAuthors} />}
                        />
                        <Route 
                            path="/404" 
                            element={<PageNotFound/>}
                        />
                        <Route 
                            path="*" 
                            element={<Navigate to="/404" replace/>}
                        />
                    </Routes>
                </Suspense>
            </div>
        </div>
    )
}

export default AppLayout;