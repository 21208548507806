const baseUrl = process.env.REACT_APP_API_URL;

export const fetchGuest = async (endpoint, data, method = 'GET') => {

	try {
		const url = `${ baseUrl }/${ endpoint }`;

		if (method ==='GET') {
			const resp = await fetch(url);

			return await resp.json(); 
		} else {
			const resp = await fetch(url,{
				method,
				headers: {
					'Accept': 'application/json',
				    'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			})

			return await resp.json(); 
		}
	} catch(e) {
		// statements
		console.log(e);
	}

}

export const fetchAuth = async (endpoint, data, method = 'GET') => {

	const url = `${ baseUrl }/${ endpoint }`;
	const token = localStorage.getItem('token') || '';

	if (method ==='GET') {
		const resp = await fetch(url, {
			headers: {
				'Accept': 'application/json',
			    'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
		});

		return await resp.json(); 
	} else {
		const resp = await fetch(url,{
			method,
			headers: {
				'Accept': 'application/json',
			    'Content-Type': 'application/json',
				'Authorization': `Bearer ${token}`
			},
			body: JSON.stringify(data)
		})

		return await resp.json(); 
	}

}

export const fetchAuthFormData = async (endpoint, data) => {

	const url = `${ baseUrl }/${ endpoint }`;
	const token = localStorage.getItem('token') || '';

	const resp = await fetch(url, {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Authorization': `Bearer ${token}`
		},
		body: data
	})

	return await resp.json(); 

}

export const fetchAuthPaginate = async (endpoint) => {

	const token = localStorage.getItem('token') || '';

	const resp = await fetch(endpoint, {
		headers: {
			'Accept': 'application/json',
		    'Content-Type': 'application/json',
			'Authorization': `Bearer ${token}`
		},
	});

	return await resp.json();

}