// contexts
import {AuthContext} from '@contexts/AuthContext';

// hooks
import {useState, useContext} from 'react';

// styled components
import {Menu, UserWrapper} from '../style';

// components
import Avatar from '@ui/Avatar';

// utils
import ClickAwayListener from '@mui/base/ClickAwayListener';

// assets
import userJpg from '@assets/avatars/user.png';
import userWebp from '@assets/avatars/user.png?as=webp';

const CurrentUser = () => {

    const {logout} = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const handleClickAway = () => setOpen(false);
    const handleClick = () => setOpen(!open);

    const src = {
        jpg: userJpg,
        webp: userWebp
    }

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <UserWrapper>
                <Avatar avatar={src} alt="avatar"/>
                <div className="info">
                    <span className="h3">Regina Gonzalez</span>
                    <span className="position">CEO - NUMANA</span>
                    <Menu className={open ? 'visible' : ''}>
                        <button type="button" onClick={logout}>
                            <i className="icon icon-logout" /> Cerrar sesión
                        </button>
                    </Menu>
                </div>
                <button className="trigger" onClick={handleClick} aria-label="Show menu">
                    <i className="icon icon-chevron-down" />
                </button>
            </UserWrapper>
        </ClickAwayListener>
    )
}

export default CurrentUser;