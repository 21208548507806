export const menu = [
    {
        icon: 'blocks',
        name: 'Calendario',
        link: '/calendar'
    },
    {
        icon: 'users',
        name: 'Pacientes',
        link: '/patients'
    },
    {
        icon: 'comment',
        name: 'Servicios',
        link: '/services'
    },
    {
        icon: 'star',
        name: 'Testimonios',
        link: '/testimonies'
    },
    {
        icon: 'wallet',
        name: 'FAQ',
        link: '/questions'
    },
    {
        cat: 'blog',
        icon: 'wallet',
        links: [
            { name: "Artículos", link: '/posts' },
            { name: "Autores", link: '/authors' }
        ]
    }
]