import { configureStore } from '@reduxjs/toolkit'
import LayoutsReducer from './features/layout'
import TodosReducer from './features/todos'
const store = configureStore({
    reducer: {
        'layout': LayoutsReducer,
        'todos': TodosReducer,
    },
})

export default store