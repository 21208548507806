// assets
import logo from '@assets/logo.png'

// styling
import styled from 'styled-components/macro';

// components
import {NavLink} from 'react-router-dom';

// utils
import PropTypes from 'prop-types';

const Img = styled.img`
  width: 200px;
  height: auto;
  max-width: 100%;
  will-change: transform;
  transition: transform var(--transition);
`

const Wrapper = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 8px;

  &:hover, &:focus {
    Img {
      transform: scale(1.2);
    }
  }
`

const Logo = ({compact}) => {
    return (
        <Wrapper as={NavLink} to="/" className="logo">
            <Img src={logo} alt="Numana"/>
        </Wrapper>
    )
}

Logo.propTypes = {
    compact: PropTypes.bool
}

export default Logo;